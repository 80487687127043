import React, { useContext, useEffect, useState } from 'react';
import { RecognizeContext } from '../App';
import logo_320 from '../img/logo_320.png';
import { Row, Col, InputGroup, FormControl, Button, Modal } from 'react-bootstrap';
import { Scanner } from '@yudiel/react-qr-scanner';
import { Link, useHistory } from 'react-router-dom';
import { BsQrCode } from 'react-icons/bs'

const ScanModal = (props) => {
	const { setUserCode } = useContext(RecognizeContext);

	const handleScan = (result) => {
		console.log(result)
		if (result.length > 0) {
			setUserCode(result[0].rawValue)
			props.onHide()
		}
	}

	return (
		<Modal {...props} size='lg' aria-labelledby='Scan' centered>
			<Modal.Header closeButton>
				<Modal.Title>QRコード読み取り</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div style={{width: 320, height: 320, margin: 'auto'}}>
					<Scanner onScan={handleScan} formats={['qr_code']} components={{audio: false}} />
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={props.onHide}>閉じる</Button>
			</Modal.Footer>
		</Modal>
	)
}

const Start = props => {
	const [modalShow, setModalShow] = useState(false)

  const { 
    setAgreed,
    orgCode,
    mode,
    userCode,
    setUserCode,
    setImage,
    setRecognizeResult,
    setTarget01,
    setTarget02,
    setTarget03,
    setTarget04,
    setTarget05,
    setTarget06,
    setTarget07,
    setTarget08,
    setTarget09,
    setGender,
    setAge,
    setChildAge
  } = useContext(RecognizeContext);
  const history = useHistory();

  useEffect(() => {
    console.log("start...");
    setUserCode("");
    setImage(undefined);
    setRecognizeResult(null);
    setTarget01(false);
    setTarget02(false);
    setTarget03(false);
    setTarget04(false);
    setTarget05(false);
    setTarget06(false);
    setTarget07(false);
    setTarget08(false);
    setTarget09(false);
    setGender("");
    setAge("");
    setChildAge("");
  }, [setUserCode, setImage, setRecognizeResult, setTarget01, setTarget02, setTarget03, setTarget04, setTarget05, setTarget06, setTarget07, setTarget08, setTarget09, setAge, setChildAge, setGender]);

  const clickStart = () => {
    if (userCode === "") {
      alert("個人番号を入力してください");
      return;
    }
    setAgreed(true);
    if (mode === 'RECOGNIZE_CAMERA') {
      history.push("/recognizeCameraGuide");
    } else {
      history.push("/recognize");
    }
  }

  return (
    (orgCode === "")
      ?
      <div>
        <h1>Not Authorized!</h1>
      </div>
      :
      <>
        <div style={{ textAlign: "center" }}>
          <div>
            <h1 style={{ fontSize: "32px" }}>DENTAL STATUS<br /><small style={{ fontSsize: "14pt" }}>口腔環境のチェック</small></h1>
          </div>
          <div>
            <img src={logo_320} alt="" style={{ width: "240px", height: "auto" }} />
          </div>
          <div>
            <Row>
              <Col xs="7">
                <Link to="/about">DENTAL STATUSとは</Link>
              </Col>
              <Col xs="5">
                <Link to="/terms">利用規約</Link>
              </Col>
            </Row>
            <div style={{ margin: "2em auto" }}>
              <InputGroup className="mb-3">
                <FormControl id='user-code' placeholder='番号入力' aria-label='番号入力' value={userCode} onChange={(event) => setUserCode(event.target.value)} />
                  <Button variant='outline-secondary' id='button-scan' onClick={() => setModalShow(true)}>
                    <Row style={{width: 160}}>
                      <Col xs={4}><BsQrCode style={{fontSize: 40, color: 'black', paddingTop: 8, paddingLeft: 8}} /></Col>
                      <Col xs={8}>QRコード<br />読み取り</Col>
                    </Row>
                  </Button>
              </InputGroup>
            </div>
            <div className="d-grid gap-2">
              <Button onClick={clickStart} size="lg">利用規約に同意して開始する</Button>
            </div>
          </div>
        </div>
        <ScanModal show={modalShow} onHide={() => setModalShow(false)} />
      </>
    );
}

export default Start